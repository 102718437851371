import gql from 'graphql-tag';

export const API_ONBOARDING_CREATE_ACCOUNT_OR_KEYWORD = gql`
  mutation onBoarding_createAccountOrKeyword(
    $projectId: Id!
    $objectInput: [ObjectInput!]!
    $streamType: StreamType!
  ) {
    onBoarding_createAccountOrKeyword(
      projectId: $projectId
      objectInput: $objectInput
      streamType: $streamType
    ) {
      id
    }
  }
`;

export const API_ONBOARDING_CREATE_ENTITY = gql`
  mutation indsight_addEntityToProject($entityIdList: [Id!]!, $projectId: Id!) {
    indsight_addEntityToProject(
      entityIdList: $entityIdList
      projectId: $projectId
    ) {
      id
      objectName
      streamType
      socialMedia
    }
  }
`;

export const API_ONBOARDING_CREATE_CLIPPING = gql`
  mutation onBoarding_createMultipleClippingV5(
    $projectId: Id!
    $clippingInputs: [ClippingInputV2Type!]!
  ) {
    onBoarding_createMultipleClippingV5(
      projectId: $projectId
      clippingInputs: $clippingInputs
    ) {
      clippingList {
        id
        name
        phrases
        startingDate
        excludePhrases
      }
    }
  }
`;

export const API_ONBOARDING_UPDATE_ROLE_AND_INDUSTRY = gql`
  mutation onBoarding_updateRoleAndIndustry(
    $projectId: Id!
    $role: String!
    $industryId: Id!
  ) {
    onBoarding_updateRoleAndIndustry(
      projectId: $projectId
      role: $role
      industryId: $industryId
    ) {
      id
      name
      role
      onBoarding {
        isOnBoarding
        role
        industryId
        advanceObjectQuota
        basicObjectQuota
        onlineMediaObjectQuota
      }
    }
  }
`;

export const API_ONBOARDING_UPDATE_PROJECT_NAME = gql`
  mutation onBoarding_updateProjectName($projectId: Id!, $name: String!) {
    onBoarding_updateProjectName(projectId: $projectId, name: $name) {
      id
      name
      role
      onBoarding {
        isOnBoarding
        role
        industryId
        advanceObjectQuota
        basicObjectQuota
        onlineMediaObjectQuota
      }
    }
  }
`;

export const API_ONBOARDING_UPDATE_STATUS_ONBOARDING = gql`
  mutation onBoarding_updateStatusOnBoarding($projectId: Id!) {
    onBoarding_updateStatusOnBoarding(projectId: $projectId) {
      id
      name
      role
    }
  }
`;
