import gql from 'graphql-tag';

export const POST_USER_REGISTER = gql`
  mutation user_registerUserV2(
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $email: String!
    $password: String!
    $promoCode: String
    $countryId: Id!
    $packagePlanId: Id!
    $zipCode: String!
    $periodSubscription: PeriodSubscription!
    $advanceObject: Int!
    $basicObject: Int!
    $onlineMediaObject: Int!
  ) {
    user_registerUserV2(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      email: $email
      password: $password
      promoCode: $promoCode
      countryId: $countryId
      packagePlanId: $packagePlanId
      zipCode: $zipCode
      periodSubscription: $periodSubscription
      advanceObject: $advanceObject
      basicObject: $basicObject
      onlineMediaObject: $onlineMediaObject
    ) {
      registerId
      __typename
    }
  }
`;

export const POST_USER_PLAN_CHECKOUT = gql`
  mutation user_planCheckout(
    $registerId: String!
    $paymentGroupCode: String
    $paymentGroupCodeChannelId: String
    $promoCode: String
  ) {
    user_planCheckout(
      registerId: $registerId
      paymentGroupCode: $paymentGroupCode
      paymentGroupCodeChannelId: $paymentGroupCodeChannelId
      promoCode: $promoCode
    ) {
      url
    }
  }
`;

export const POST_COMPLETE_REGISTER_APPSUMO = gql`
  mutation user_completeRegisterAppSumo(
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $email: String!
    $password: String!
    $countryId: Id!
    $zipCode: String!
    $registerId: Id!
  ) {
    user_completeRegisterAppSumo(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      email: $email
      password: $password
      countryId: $countryId
      zipCode: $zipCode
      registerId: $registerId
    ) {
      registerId
      __typename
    }
  }
`;

export const POST_USER_PLAN_CHECKOUT_V2 = gql`
  mutation billing_planCheckoutV2(
    $fullName: String!
    $companyName: String!
    $email: String!
    $password: String!
    $promoCode: String
    $countryId: Id!
    $period: Int!
    $planItems: [PlanItem!]!
  ) {
    billing_planCheckoutV2(
      fullName: $fullName
      companyName: $companyName
      email: $email
      password: $password
      promoCode: $promoCode
      countryId: $countryId
      period: $period
      planItems: $planItems
    ) {
      url
    }
  }
`;
