import gql from 'graphql-tag';

export const ONBOARDING_GET_INDUSTRY = gql`
  query onBoarding_getIndustry {
    onBoarding_getIndustry {
      id
      name
    }
  }
`;

export const ONBOARDING_GET_PREPARATION = gql`
  query onBoarding_getDataPreparationProgress($projectId: Id!) {
    onBoarding_getDataPreparationProgress(projectId: $projectId) {
      status
      streamCount
      objectCount
      createdAt
      updatedAt
    }
  }
`;

export const ONBOARDING_GET_COUNTRY = gql`
  query onBoarding_getCountryByProjectId($projectId: Id!) {
    onBoarding_getCountryByProjectId(projectId: $projectId) {
      id
      name
      countryCode
      currency
    }
  }
`;

export const ONBOARDING_GET_ENTITY_BY_NAME = gql`
  query indsight_searchEntityByName($name: String!) {
    indsight_searchEntityByName(name: $name) {
      entityId
      entityName
      category
      categoryParent
      displayPicture
    }
  }
`;
