/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Sidebar from './Sidebar';
import Header from './Header';
import HeaderOnlineMedia from './HeaderOnlineMedia';
import HeaderOnlineMediaClipping from './HeaderOnlineMediaClipping';
import HeaderSocialMediaStream from './HeaderSocialMediaStream';
import HeaderSettings from './HeaderSettings';
import HeaderCustomDashboard from './HeaderCustomDashboard';
import TopHeader from './Header/Top';
import { useLocation, useNavigate } from 'react-router';
import {
  ONLINE_MEDIA_CLIPPING,
  SOCIAL_MEDIA_CAMPAIGN,
  STREAM_PANEL_ONLINE_MEDIA,
  STREAM_PANEL_SOCIAL_MEDIA,
  SETTINGS,
  ONLINE_MEDIA_STATISTIC,
  CUSTOM_DASHBOARD,
  ADD_AUTO_LABEL,
  SOCIAL_MEDIA_COMPARISON,
  SOCIAL_MEDIA_ADD_COMPARISON,
  ONLINE_MEDIA_COMPARISON,
  PEOPLE_PANEL,
  STREAM_PANEL_OFFLINE_MEDIA,
  OFFLINE_MEDIA_STATISTIC,
  CUSTOM_REPORT,
  ADD_AUTO_LABEL_ONM,
  SETTINGS_LABEL,
  OFFLINE_MEDIA_CLIPPING,
  OFFLINE_MEDIA_COMPARISON
} from 'src/route';
import HeaderOnlineMediaClippingById from './HeaderOnlineMediaClippingById';
import useQueryUrl from 'src/hooks/useQuery';
import HeaderSocialMediaHashtag from './HeaderSocialMediaHashtag';
import HeaderPreferenceCampaign from './HeaderPreferenceCampaign';
import MinimalistHeader from './MinimalistHeader';
import HeaderOnlineMediaStatistic from './HeaderOnlineMediaStatistic';
import HeaderComparisonDashboard from './HeaderComparisonDashboard';
import HeaderComparisonOnm from './HeaderComparisonOnm';
import { useAlert } from 'src/hooks/useAlert';
import { useAppSelector } from 'src/app/hooks';
import { useTopNotification } from 'src/hooks/useTopNotification';
import TopNotification from '../components/TopNotification';
import HeaderPeoplePanel from './HeaderPeoplePanel';
import HeaderOfflineMedia from './HeaderOfflineMedia';
import HeaderOfflineMediaStatistic from './HeaderOfflineMediaStatistic';
import HeaderCustomReport from './HeaderCustomReport';
import HeaderCustomReportById from './HeaderCustomReportById';
import HeaderOfflineMediaClippingById from './HeaderOfflineMediaClippingById';
import HeaderOfflineMediaComparison from 'src/layouts/SidebarLayout/HeaderOfflineMediaComparison';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
    width: 100vw;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1 1 0%;
    background: white;
    cursor: default
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
`
);

const MainBody = styled(Box)(
  ({ theme }) => `
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
  height: calc(100vh - 45px);
  max-height: 100%;
  position: relative;
  overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { handleCloseAlert, handleClickAlert } = useAlert();
  const stateAlert = useAppSelector((state) => state.storeAlert.alert);
  const {
    status,
    isProviderLtd,
    subscriptionEndDate: endDateString,
    customerType
  } = useAppSelector((state) => state.users.project);
  const subscriptionEndDate = new Date(endDateString);
  const { pathname } = useLocation();
  const pathValue = pathname.split('/')[3];
  const pathValueSetting = pathname.split('/')[2];
  const pathValueComparison = pathname.split('/')[4];
  const query = useQueryUrl();
  const preferences = query.get('preferences');
  const history = query.get('history');
  const tabComparison = query.get('tab');
  const navigator = useNavigate();
  const theme = useTheme();
  const { handleShowTopNotification, handleCloseNotification } =
    useTopNotification();
  const getTopPadding = () => {
    switch (pathname) {
      // case STREAM_PANEL_SOCIAL_MEDIA:
      //   return 0;
      // case STREAM_PANEL_ONLINE_MEDIA:
      // return portalId ? 0 : 0;
      // case `${ONLINE_MEDIA_CLIPPING}/${pathValue}`:
      //   return 0;
      case ONLINE_MEDIA_CLIPPING:
        return 4;
      case OFFLINE_MEDIA_CLIPPING:
        return 4;
      case SOCIAL_MEDIA_CAMPAIGN:
        if (!preferences && !history) return 0;
        return 3;
      case `${SETTINGS}/${pathValueSetting}`:
        return 4;
      case SETTINGS:
        return 4;
      case ADD_AUTO_LABEL:
        return 4;
      case ADD_AUTO_LABEL_ONM:
        return 4;
      case SOCIAL_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard') return 0;
        return 4;
      case ONLINE_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard') return 0;
        return 4;
      case OFFLINE_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard') return 0;
        return 4;
      case `${SOCIAL_MEDIA_ADD_COMPARISON}/${pathValueComparison}`:
        return 4;
      case CUSTOM_DASHBOARD:
        return 12;
      case CUSTOM_REPORT:
        return 4;
      default:
        return 0;
    }
  };

  const getHeader = () => {
    switch (pathname) {
      case STREAM_PANEL_SOCIAL_MEDIA:
        return <HeaderSocialMediaStream />;
      case STREAM_PANEL_ONLINE_MEDIA:
        return <HeaderOnlineMedia />;
      case STREAM_PANEL_OFFLINE_MEDIA:
        return <HeaderOfflineMedia />;
      case PEOPLE_PANEL:
        return <HeaderPeoplePanel />;
      case ONLINE_MEDIA_CLIPPING:
        return <HeaderOnlineMediaClipping />;
      case OFFLINE_MEDIA_CLIPPING:
        return <HeaderOnlineMediaClipping />;
      case `${ONLINE_MEDIA_CLIPPING}/${pathValue}`:
        return <HeaderOnlineMediaClippingById />;
      case `${OFFLINE_MEDIA_CLIPPING}/${pathValue}`:
        return <HeaderOfflineMediaClippingById />;
      case ONLINE_MEDIA_STATISTIC:
        return <HeaderOnlineMediaStatistic />;
      case OFFLINE_MEDIA_STATISTIC:
        return <HeaderOfflineMediaStatistic />;
      case ONLINE_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard')
          return <HeaderComparisonOnm onClick={() => navigator(SETTINGS)} />;
        return (
          <MinimalistHeader
            onClick={() => navigator(ONLINE_MEDIA_COMPARISON)}
            title={pathValueSetting}
            showBackButton={false}
          />
        );
      case OFFLINE_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard')
          return (
            <HeaderOfflineMediaComparison onClick={() => navigator(SETTINGS)} />
          );
        return (
          <MinimalistHeader
            onClick={() => navigator(OFFLINE_MEDIA_COMPARISON)}
            title={pathValueSetting}
            showBackButton={false}
          />
        );
      case SOCIAL_MEDIA_CAMPAIGN:
        if (!preferences && !history) return <HeaderSocialMediaHashtag />;
        if (preferences) return <HeaderPreferenceCampaign />;
        return (
          <MinimalistHeader
            onClick={() => navigator(SOCIAL_MEDIA_CAMPAIGN)}
            title="Campaign History"
          />
        );
      case SOCIAL_MEDIA_COMPARISON:
        if (tabComparison === 'dashboard')
          return (
            <HeaderComparisonDashboard onClick={() => navigator(SETTINGS)} />
          );
        return (
          <MinimalistHeader
            onClick={() => navigator(SOCIAL_MEDIA_COMPARISON)}
            title={pathValueSetting}
            showBackButton={false}
          />
        );
      case `${SOCIAL_MEDIA_ADD_COMPARISON}/${pathValueComparison}`:
        return (
          <MinimalistHeader
            onClick={() => navigator(SOCIAL_MEDIA_COMPARISON)}
            title={pathValueSetting}
          />
        );
      case SETTINGS:
        return <HeaderSettings />;
      case `${SETTINGS}/${pathValueSetting}`:
        return (
          <MinimalistHeader
            onClick={() => navigator(SETTINGS)}
            title={pathValueSetting}
          />
        );
      case CUSTOM_DASHBOARD:
        return <HeaderCustomDashboard />;
      case `${ADD_AUTO_LABEL}`:
        return (
          <MinimalistHeader
            onClick={() => navigator(SETTINGS)}
            title={'Label'}
          />
        );
      case `${ADD_AUTO_LABEL_ONM}`:
        return (
          <MinimalistHeader
            onClick={() => navigator(`${SETTINGS_LABEL}?tab=auto_label_onm`)}
            title="Label Article"
          />
        );
      case CUSTOM_REPORT:
        return <HeaderCustomReport />;
      case `${CUSTOM_REPORT}/${pathValue}`:
        return <HeaderCustomReportById />;
      default:
        return <Header />;
    }
  };

  useEffect(() => {
    if (
      !status ||
      isProviderLtd ||
      customerType === 'b2b' ||
      !subscriptionEndDate
    )
      return;
    if (status === 'active') return;

    const terminatedDate = new Date(subscriptionEndDate);
    terminatedDate.setDate(terminatedDate.getDate() + 14);

    if (status === 'almost_on_hold') {
      const remainingDays = Math.round(
        (subscriptionEndDate.getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      );
      handleShowTopNotification({
        message: `Your next billing is due in ${remainingDays} ${
          remainingDays > 1 ? 'days' : 'day'
        } - Update Your Payment Now`,
        severity: 'info',
        showCloseButton: true
      });
    } else if (status === 'on_hold') {
      handleShowTopNotification({
        message:
          'You’re on a grace period! Your subscription data are on hold - Update Your Payment Now',
        severity: 'error',
        showCloseButton: false
      });
    } else if (status === 'almost_terminated') {
      const remainingDays = Math.round(
        (new Date().getTime() - terminatedDate.getTime()) / (1000 * 3600 * 24)
      );
      handleShowTopNotification({
        message: `Your plan has expired! Your subscription data will not be updated in the next ${remainingDays} ${
          remainingDays > 1 ? 'days' : 'day'
        } - Update Your Payment Now`,
        severity: 'error',
        showCloseButton: false
      });
    } else if (status === 'terminated') {
      handleShowTopNotification({
        message: 'Your subscription is terminated - Update Your Payment Now',
        severity: 'error',
        showCloseButton: false
      });
    } else {
      handleCloseNotification();
    }
  }, [status, subscriptionEndDate, customerType]);

  return (
    <>
      <MainWrapper>
        <Sidebar />
        <MainContent>
          <TopNotification />
          <TopHeader />
          <ErrorBoundary
            FallbackComponent={() => <></>}
            onError={(error: Error, info) => {
              handleClickAlert({
                horizontal: 'center',
                vertical: 'top',
                message: 'Something went wrong',
                severity: 'error'
              });
              console.log(error, info.componentStack);
            }}
          >
            <MainBody>
              {getHeader()}
              <Box
                sx={{
                  px: 4,
                  pt: getTopPadding(),
                  pb: 4,
                  background: theme.palette.grey[50]
                }}
              >
                <Outlet />
              </Box>
            </MainBody>
          </ErrorBoundary>
        </MainContent>
      </MainWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: stateAlert.vertical,
          horizontal: stateAlert.horizontal
        }}
        open={stateAlert.open}
        onClose={handleCloseAlert}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={stateAlert.severity}
          sx={{ width: '100%' }}
        >
          {stateAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SidebarLayout;
