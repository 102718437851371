import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { IDateRange } from 'src/models/general';
import { combineDateAndTime } from 'src/utils/combineDateAndTime';

interface Props {
  onClose: () => void;
  onSet: (dateRange: IDateRange) => void;
  open: boolean;
  maxDate?: Date;
}

const ModalCustomTimeFrame: React.FC<Props> = ({
  onClose,
  onSet,
  open,
  maxDate
}) => {
  const dateRange = useAppSelector(
    (state) => state.analyticArguments.dateRange
  );

  const [fromDate, setFromDate] = useState<Date | null | string>(
    dateRange.start
  );
  const [fromTime, setFromTime] = useState<Date | null | string>(
    dateRange.start
  );
  const [toDate, setToDate] = useState<Date | null | string>(dateRange.end);
  const [toTime, setToTime] = useState<Date | null | string>(dateRange.end);

  const theme = useTheme();
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'sm'}>
      <DialogTitle
        sx={{
          m: theme.spacing(3, 3, 1)
        }}
      >
        <Typography variant="h3">Custom date filters</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          m: theme.spacing(1, 3)
        }}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Typography
            variant="subtitle1"
            sx={{
              width: theme.spacing(10)
            }}
          >
            From
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={fromDate}
              minDate={new Date('2019-01-01')}
              maxDate={maxDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              disableFuture
            />
            <DesktopTimePicker
              value={fromTime}
              onChange={(newValue) => {
                setFromTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={3} sx={{ mt: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              width: theme.spacing(10)
            }}
          >
            To
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={toDate}
              minDate={fromDate}
              maxDate={maxDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              disableFuture
            />
            <DesktopTimePicker
              value={toTime}
              onChange={(newValue) => {
                setToTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: theme.spacing(0, 5, 5, 5)
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: theme.spacing(20)
          }}
          size="large"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            background: theme.palette.primary.dark,
            width: theme.spacing(20)
          }}
          size="large"
          onClick={() =>
            onSet({
              start: combineDateAndTime(fromDate as Date, fromTime as Date),
              end: combineDateAndTime(toDate as Date, toTime as Date)
            })
          }
        >
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCustomTimeFrame;
