import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { useAppSelector } from 'src/app/hooks';
import { Box } from '@mui/system';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import * as Yup from 'yup';
import { FieldArray, useFormik } from 'formik';
import { IAutoClippingPayload } from 'src/models/onlineMedia';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { withStyles } from '@mui/styles';
import useOnlineMedia from 'src/services/useOnlineMedia';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { TMediaType } from 'src/models/offlineMedia';
import useOfflineMedia from 'src/services/useOfflineMedia';
import Information from '../Information';
import { useAlert } from 'src/hooks/useAlert';
import { CustomGraphQLError } from 'src/models/general';

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input':
      {
        padding: 0
      }
  }
})((props: any) => <Autocomplete {...props} />);

function validationSchema() {
  return Yup.object({
    name: Yup.string().required(),
    startingDate: Yup.date().required(),
    // startingTime: Yup.date().required(),
    phrasesList: Yup.array().of(
      Yup.array().of(
        Yup.string()
          .min(2, 'Phrase must contain at least two characters')
          .matches(
            /^[^;{}[\]?/\\|]*$/,
            'Phrase must not contain these characters: ; { } [ ] ? / \\ |'
          )
      )
    ),
    excludePhrases: Yup.array().of(
      Yup.string()
        .min(2, 'Phrase must contain at least two characters')
        .matches(
          /^[^;{}[\]?/\\|]*$/,
          'Phrase must not contain these characters: ; { } [ ] ? / \\ |'
        )
    )
  });
}

interface IProps {
  clippingType?: TMediaType;
  onCompletedSubmit?: () => void;
}

const ModalAddAutoClipping = ({
  clippingType = 'onlineMedia',
  onCompletedSubmit = () => {}
}: IProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const theme = useTheme();
  const { handleClickAlert } = useAlert();
  const projectId = useAppSelector((state) => state.users.project.id);
  const clippingList = useAppSelector(
    (state) => state.storeOnlineMediaStream.clippingList.data
  );
  const { getClippingsV5, addClippingV5, responseAddClippingV5 } =
    useOnlineMedia();

  const { addClippingOfflineMedia, resAddClippingOfflineMedia } =
    useOfflineMedia();

  const {
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    values,
    touched,
    resetForm,
    isSubmitting,
    setSubmitting
  } = useFormik<IAutoClippingPayload>({
    initialValues: {
      name: '',
      phrasesList: [[]],
      startingDate: new Date(),
      excludePhrases: []
      // startingTime: new Date()
    },
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      setSubmitting(true);
      const formattedDate = moment(
        values.startingDate.setHours(0, 0, 0, 0)
      ).format('YYYY-MM-DD HH:mm:ss');

      // add onm clipping
      if (clippingType === 'onlineMedia') {
        addClippingV5({
          variables: {
            ...values,
            projectId,
            startingDate: formattedDate
          }
        }).then((data) => {
          setSubmitting(false);
          if (data.errors) {
            const errorResponse = data.errors as CustomGraphQLError[];
            const [message] = errorResponse[0].extensions.reason.failures;
            handleClickAlert({
              message: message,
              horizontal: 'center',
              vertical: 'top',
              severity: 'error'
            });
            return;
          }
          handleClose();
          getClippingsV5({
            projectId,
            page: clippingList.page,
            limit: clippingList.limit,
            name: ''
          });
        });
        return;
      }
      // add ofm clipping
      addClippingOfflineMedia({
        variables: {
          ...values,
          projectId,
          startingDate: formattedDate,
          categoryArticle: clippingType
        },
        onCompleted: () => {
          setSubmitting(false);
          handleClose();
          onCompletedSubmit();
        }
      });
    }
  });

  return (
    <>
      <Button
        variant="contained"
        sx={{
          borderRadius: '6px',
          height: theme.spacing(5),
          minWidth: theme.spacing(6),
          background: theme.palette.primary.dark,
          color: theme.colors.primary.lighter
        }}
        onClick={handleClickOpen}
        endIcon={<TurnedInNotIcon />}
      >
        Make Clipping
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'md'}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 3, 0, 3)
          }}
        >
          <Typography variant="h3">Auto Clipping</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <Typography variant="caption">
                  Create automated clipping to gather information needed by
                  curated source and media
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <Grid item sm={8}>
                    <Typography sx={{ mb: theme.spacing(0.5) }} variant="h4">
                      Auto Clipping Name
                    </Typography>
                    <FormControl fullWidth size="medium">
                      <OutlinedInput
                        name="name"
                        onChange={handleChange}
                        placeholder="Auto clipping name"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText
                        error
                        variant="outlined"
                        margin="dense"
                        sx={{ ml: 0 }}
                      >
                        {errors.name && touched.name && errors.name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <Box>
                      <Typography variant="h4">Starting Date</Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack gap={1} direction="row">
                          <DesktopDatePicker
                            value={values.startingDate}
                            // minDate={new Date()}
                            onChange={(newValue) => {
                              setFieldValue('startingDate', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          {/* <DesktopTimePicker
                            value={values.startingTime}
                            onChange={(newValue) => {
                              setFieldValue('startingTime', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          /> */}
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item sm={12}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography variant="h4" sx={{ mb: theme.spacing(0.5) }}>
                        Phrases
                      </Typography>
                      <Information
                        content={
                          <p>
                            Input phrases{' '}
                            <strong>
                              must contain at least two characters
                            </strong>{' '}
                            and{' '}
                            <strong>not include the following symbols</strong>:
                            ; {} [] ? / \ |
                          </p>
                        }
                        color={theme.colors.primary.main}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mb: theme.spacing(1) }}
                    >
                      <Typography variant="body1">Include</Typography>
                    </Stack>
                    <FieldArray name="phrasesList" validateOnChange={false}>
                      {({ push, remove, handlePush }) => (
                        <Grid
                          container
                          sx={{
                            maxHeight: theme.spacing(16),
                            overflow: 'auto'
                          }}
                        >
                          {values.phrasesList.map((rows, index) => {
                            return (
                              <Grid item xs={6}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ mb: theme.spacing(0.5) }}
                                  key={index}
                                >
                                  <CustomAutocomplete
                                    multiple
                                    size="small"
                                    options={[]}
                                    defaultValue={[]}
                                    freeSolo
                                    value={rows}
                                    renderTags={(
                                      values: Array<any>,
                                      getTagProps
                                    ) =>
                                      values.map(
                                        (option: string, index: number) => (
                                          <Chip
                                            key={`${option}-${index}-phrases`}
                                            variant="outlined"
                                            label={
                                              <Typography>{option}</Typography>
                                            }
                                            size="small"
                                            {...getTagProps({ index })}
                                          />
                                        )
                                      )
                                    }
                                    sx={{ width: '90%' }}
                                    onChange={(_e, value) => {
                                      setFieldValue(
                                        `phrasesList[${index}]`,
                                        value
                                      );
                                    }}
                                    //onChange={handleChange}
                                    forcePopupIcon={false}
                                    disableClearable
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        autoComplete="off"
                                        placeholder="Separated by Enter"
                                        onPaste={(event) => {
                                          let keywords =
                                            event.clipboardData.getData('text');
                                          keywords = keywords.replace(
                                            /[\u201C\u201D]/g,
                                            '"'
                                          );
                                          const splitKeyword =
                                            keywords.split(',');
                                          const trimAndFilterKeyword =
                                            splitKeyword
                                              .filter(
                                                (word) => word.trim().length > 1
                                              )
                                              .map((word) => word.trim());
                                          const value = [
                                            ...values.phrasesList[index],
                                            ...trimAndFilterKeyword
                                          ];
                                          setFieldValue(
                                            `phrasesList[${index}]`,
                                            value
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                  <Button
                                    onClick={() =>
                                      setFieldValue(`phrasesList`, [
                                        ...values.phrasesList,
                                        []
                                      ])
                                    }
                                  >
                                    OR
                                  </Button>
                                </Stack>
                              </Grid>
                            );
                          })}
                        </Grid>
                      )}
                    </FieldArray>
                    <FormHelperText
                      error
                      variant="outlined"
                      margin="dense"
                      sx={{ ml: 0 }}
                    >
                      {errors.phrasesList &&
                        touched.phrasesList &&
                        errors.phrasesList}
                    </FormHelperText>
                  </Grid>
                  {clippingType === 'onlineMedia' ? (
                    <Grid item xs={12} sm={8}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: theme.spacing(1) }}
                      >
                        <Typography variant="body1">Exclude</Typography>
                      </Stack>
                      <CustomAutocomplete
                        multiple
                        id="keyword-excludes"
                        size="small"
                        options={[]}
                        defaultValue={[]}
                        freeSolo
                        value={values.excludePhrases}
                        renderTags={(values: Array<any>, getTagProps) =>
                          values.map((option: string, index: number) => (
                            <Chip
                              key={`${option}-${index}-excludes`}
                              variant="outlined"
                              label={<Typography>{option}</Typography>}
                              size="small"
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_e, value) => {
                          setFieldValue('excludePhrases', value);
                        }}
                        forcePopupIcon={false}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="excludes"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            placeholder="Separated by Enter"
                            onPaste={(event) => {
                              const keywords =
                                event.clipboardData.getData('text');
                              const splitKeyword = keywords.split(',');
                              const trimAndFilterKeyword = splitKeyword
                                .filter((word) => word.trim().length > 1)
                                .map((word) => word.trim());
                              const value = [
                                ...values.excludePhrases,
                                ...trimAndFilterKeyword
                              ];
                              setFieldValue('excludePhrases', value);
                            }}
                          />
                        )}
                      />
                      <FormHelperText
                        error
                        variant="outlined"
                        margin="dense"
                        sx={{ ml: 0 }}
                      >
                        {errors.excludePhrases &&
                          touched.excludePhrases &&
                          errors.excludePhrases}
                      </FormHelperText>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    endIcon={false ? <CircularProgress size={20} /> : null}
                    type="submit"
                    size="large"
                    sx={{ width: theme.spacing(20), mt: theme.spacing(3) }}
                    disabled={responseAddClippingV5.loading || isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalAddAutoClipping;
