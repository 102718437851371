import gql from 'graphql-tag';

export const GET_USER_GET_PACKAGE_PLAN = gql`
  query user_getPackagePlan {
    user_getPackagePlan {
      id
      name
      displayName
      advanceObjectQuota
      basicObjectQuota
      onlineMediaObjectQuota
    }
  }
`;

export const GET_USER_PLAN_SIMULATION = gql`
  query user_getPlanSimulation(
    $basicObject: Int!
    $advanceObject: Int!
    $onlineMediaObject: Int!
    $countryId: Id!
    $period: Int!
    $promoCode: String
    $packagePlanId: Id!
  ) {
    user_getPlanSimulation(
      basicObject: $basicObject
      advanceObject: $advanceObject
      onlineMediaObject: $onlineMediaObject
      countryId: $countryId
      period: $period
      promoCode: $promoCode
      packagePlanId: $packagePlanId
    ) {
      isUsedPromo
      isPromoValid
      advanceObject
      isLtd
      promoErrorMessage
      currency
      currencyBySelectedCountry
      quotaBasic
      quotaAdvance
      quotaOnlineMedia
      total
      totalBySelectedCountry
      period
      promoType
      merchantName
      promoCode
      symbolBySelectedCountry
    }
  }
`;

export const GET_USER_COUNTRIES = gql`
  query billing_getCountry {
    billing_getCountry {
      id
      name
      countryCode
      currency
      __typename
    }
  }
`;

export const GET_XENDIT_PAYMENT_CHANNEL = gql`
  query user_getXenditPaymentChannel {
    user_getXenditPaymentChannel {
      paymentGroup {
        groupName
        groupCode
        groupIcon
        channels {
          id
          name
          logo
        }
      }
      __typename
    }
  }
`;

export const GET_PLAN_SIMULATION_BY_ID = gql`
  query user_getPlanSimulationByRegisterId($registerId: Id!) {
    user_getPlanSimulationByRegisterId(registerId: $registerId) {
      isUsedPromo
      advanceObject
      isLtd
      isPromoValid
      promoErrorMessage
      currency
      currencyBySelectedCountry
      quotaBasic
      quotaAdvance
      quotaOnlineMedia
      period
      total
      totalBySelectedCountry
      promoType
      promoCode
      merchantName
      symbolBySelectedCountry
      packagePlan {
        advanceObjectQuota
        basicObjectQuota
        displayName
        id
        name
        onlineMediaObjectQuota
      }
      __typename
    }
  }
`;

export const GET_BILLABLE_METRICS = gql`
  query billing_getBillableMetrics {
    billing_getBillableMetrics {
      id
      name
      description
      code
    }
  }
`;

export const GET_PLAN_SIMULATION_V2 = gql`
  query billing_getPlanSimulationV2(
    $planItems: [PlanItem!]!
    $countryId: Id!
    $period: Int!
    $promoCode: String
  ) {
    billing_getPlanSimulationV2(
      planItems: $planItems
      countryId: $countryId
      period: $period
      promoCode: $promoCode
    ) {
      planItems {
        billableMetricId
        quota
      }
      isPromoValid
      promoErrorMessage
      currency
      total
      period
      promoType
      promoCode
      discount
      subTotal
    }
  }
`;
