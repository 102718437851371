import React, { Suspense, lazy, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import BasicLayout from 'src/layouts/BasicLayout';

//import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  SOCIAL_MEDIA,
  ONBOARDING,
  ONBOARDING_ADD_PROFILE,
  REGISTER,
  REGISTER_ACCOUNT,
  STATUS_LOADING,
  USERS,
  USERS_SIGN_IN,
  STREAM_PANEL,
  STREAM_PANEL_SOCIAL_MEDIA,
  ONLINE_MEDIA,
  ONLINE_MEDIA_CLIPPING,
  SOCIAL_MEDIA_CONTENT,
  CUSTOM_DASHBOARD,
  SOCIAL_MEDIA_OVERVIEW,
  PEOPLE_PANEL,
  OFFLINE_MEDIA,
  CUSTOM_REPORT,
  OFFLINE_MEDIA_CLIPPING
} from './route';
import Account from './content/register/account';
import Checkout from './content/register/checkout';

interface IProtectedRouter {
  isAuth: boolean;
  children: React.ReactChild;
}

interface IProtectedOnboarding {
  isOnboarding: boolean;
  children: React.ReactChild;
}

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  );

const ProtectedRouter: FC<IProtectedRouter> = ({ children, isAuth }) => {
  return <>{!isAuth ? <Navigate to={USERS_SIGN_IN} /> : children}</>;
};

const ProtectedOnboarding: FC<IProtectedOnboarding> = ({
  children,
  isOnboarding
}) => {
  return (
    <>
      {isOnboarding ? (
        <Navigate to={SOCIAL_MEDIA_OVERVIEW} replace />
      ) : (
        children
      )}
    </>
  );
};

// Pages

//const Overview = Loader(lazy(() => import('src/content/overview')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusLoading = Loader(
  lazy(() => import('src/content/pages/Status/Loading'))
);

// Register
const RegisterSuccess = Loader(
  lazy(() => import('./content/register/success'))
);

const RegisterAppSumo = Loader(
  lazy(() => import('./content/register/appsumo'))
);

//Users
const UsersSignIn = Loader(lazy(() => import('./content/users/SignIn')));

const UsersForgotPassword = Loader(
  lazy(() => import('./content/users/ForgotPassword'))
);

const UsersResetPassword = Loader(
  lazy(() => import('./content/users/ResetPassword'))
);

//Billing
const UpgradeStreamQuota = Loader(
  lazy(() => import('./content/settings/Billing/UpgradeStreamQuota'))
);
const CheckoutStream = Loader(
  lazy(() => import('./content/settings/Billing/CheckoutStream'))
);

const UpdatePlan = Loader(
  lazy(() => import('./content/settings/Billing/UpdatePlan'))
);

const ChooseYourPlan = Loader(
  lazy(() => import('./content/settings/Billing/ChooseYourPlan'))
);

const CheckoutPlan = Loader(
  lazy(() => import('./content/settings/Billing/CheckoutPlan'))
);

const ManageObjectOverlap = Loader(
  lazy(() => import('./content/settings/Billing/ManageObjectOverlap'))
);

//Onboarding
const CreateProfile = Loader(
  lazy(() => import('./content/onboarding/CreateProfile'))
);
const CreateProject = Loader(
  lazy(() => import('./content/onboarding/CreateProject'))
);
const CreateSocialProfile = Loader(
  lazy(() => import('./content/onboarding/CreateSocialProfile'))
);

const CreateKeywordHashtag = Loader(
  lazy(() => import('./content/onboarding/CreateKeywordOrHashtag'))
);

const CreateClipping = Loader(
  lazy(() => import('./content/onboarding/CreateClipping'))
);

const SetupProgress = Loader(
  lazy(() => import('./content/onboarding/SetupProgress'))
);

const PopulatingData = Loader(
  lazy(() => import('./content/onboarding/PopulatingData'))
);

const CreateEntity = Loader(
  lazy(() => import('./content/onboarding/CreateEntity'))
);

//Monitoring
const MonitoringOverview = Loader(
  lazy(() => import('./content/monitoring/Overview'))
);
const MonitoringContent = Loader(
  lazy(() => import('./content/monitoring/Content'))
);
const MonitoringCommunity = Loader(
  lazy(() => import('./content/monitoring/Community'))
);
const MonitoringCampaign = Loader(
  lazy(() => import('./content/monitoring/Campaign'))
);
const MonitoringConversation = Loader(
  lazy(() => import('./content/monitoring/Conversation'))
);
const MonitoringComparison = Loader(
  lazy(() => import('./content/monitoring/Comparison'))
);
const MonitoringComparisonAddForm = Loader(
  lazy(() => import('./content/monitoring/Comparison/FormAdd'))
);
const Sna = Loader(lazy(() => import('./content/monitoring/Sna')));
const Dna = Loader(lazy(() => import('./content/monitoring/Dna')));
const Clustering = Loader(
  lazy(() => import('./content/monitoring/Clustering'))
);

//monitoring online media
const MonitoringOnlineMediaStatistic = Loader(
  lazy(() => import('./content/monitoring/online_media/Statistic'))
);
const MonitoringOnlineMediaClipping = Loader(
  lazy(() => import('./content/monitoring/online_media/ClippingList'))
);
const MonitoringOnlineMediaClippingById = Loader(
  lazy(() => import('./content/monitoring/online_media/ClippingById'))
);
const MonitoringOnlineMediaComparison = Loader(
  lazy(() => import('./content/monitoring/online_media/Comparison'))
);

//Monitoring Offline Media
const MonitoringOfflineMediaStatistic = Loader(
  lazy(() => import('./content/monitoring/offline_media/statistic'))
);
const OfflineMediaClipping = Loader(
  lazy(() => import('./content/monitoring/offline_media/Clippings'))
);
const MonitoringOfflineMediaClippingById = Loader(
  lazy(() => import('./content/monitoring/offline_media/ClippingById'))
);
const OfflineMediaComparison = Loader(
  lazy(() => import('./content/monitoring/offline_media/Comparison'))
);

//Stream Panel
const StreamPanelSocialMedia = Loader(
  lazy(() => import('./content/streamPanel/socialMedia'))
);

const StreamPanelOnlineMedia = Loader(
  lazy(() => import('./content/streamPanel/onlineMedia'))
);

const CustomDashboard = Loader(
  lazy(() => import('./content/main/CustomDashboard'))
);

const StreamPanelOfflineMedia = Loader(
  lazy(() => import('./content/streamPanel/offlineMedia'))
);
// Custom Report
const CustomReport = Loader(lazy(() => import('./content/main/CustomReport')));
const CustomReportById = Loader(
  lazy(() => import('./content/main/CustomReport/CustomReportById'))
);

// Settings
const Settings = Loader(lazy(() => import('./content/settings')));
const SettingsObjects = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Objects'))
);
const SettingsGroup = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Group'))
);
const SettingsLabel = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Label'))
);
const SettingsCustomDashboard = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/CustomDashboard'))
);
const SettingsEntity = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Entity'))
);
const AddAutoLabel = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Label/AddAutoLabel'))
);
const ObjectOverlap = Loader(
  lazy(() => import('./content/settings/ObjectOverlap'))
);
const AddAutoLabelOnm = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/Label/AddAutoLabelOnm'))
);

const LoadingProcess = Loader(
  lazy(() => import('./content/pages/Status/LoadingProcess'))
);

const RegisterInvitation = Loader(
  lazy(() => import('./content/users/RegisterInvitation'))
);

const PeoplePanel = Loader(
  lazy(() => import('./content/streamPanel/peoplePanel'))
);

const SettingsStopWords = Loader(
  lazy(() => import('./content/settings/MonitoredAssets/StopWords'))
);

const routes = (
  isAuthenticated: boolean,
  isOnboarding: boolean,
  isNetworkAnalysis: boolean,
  isCustomReport: boolean,
  isClustering: boolean
): PartialRouteObject[] => [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: isAuthenticated ? (
          <Navigate to={STATUS_LOADING} replace />
        ) : (
          <UsersSignIn />
        )
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'loading',
            element: (
              <ProtectedRouter isAuth={isAuthenticated}>
                <StatusLoading />
              </ProtectedRouter>
            )
          }
        ]
      },
      {
        path: 'recovery-password',
        element: <UsersResetPassword />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: REGISTER,
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <Navigate to={`${REGISTER_ACCOUNT}?plan=lite&currency=idr`} replace />
        )
      },
      {
        path: 'appsumo',
        element: <RegisterAppSumo />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'checkout',
        element: <Checkout />
      },
      {
        path: 'success',
        element: <RegisterSuccess />
      }
    ]
  },
  {
    path: USERS,
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={USERS_SIGN_IN} replace />
      },
      {
        path: 'sign_in',
        element: isAuthenticated ? (
          <Navigate to={STATUS_LOADING} replace />
        ) : (
          <UsersSignIn />
        )
      },
      {
        path: 'forgot_password',
        element: <UsersForgotPassword />
      }
    ]
  },
  {
    path: SOCIAL_MEDIA,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
      },
      {
        path: 'overview',
        element: <MonitoringOverview />
      },
      {
        path: 'content',
        element: <MonitoringContent />
      },
      {
        path: 'community',
        element: <MonitoringCommunity />
      },
      {
        path: 'campaign',
        element: <MonitoringCampaign />
      },
      {
        path: 'conversation',
        element: <MonitoringConversation />
      },
      {
        path: 'comparison',
        element: <MonitoringComparison />
      },
      {
        path: 'comparison/form-add/:category',
        element: <MonitoringComparisonAddForm />
      },
      {
        path: 'sna',
        element: !isNetworkAnalysis ? (
          <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
        ) : (
          <Sna />
        )
      },
      {
        path: 'dna',
        element: !isNetworkAnalysis ? (
          <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
        ) : (
          <Dna />
        )
      },
      {
        path: 'clustering',
        element: !isClustering ? (
          <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
        ) : (
          <Clustering />
        )
      }
    ]
  },
  {
    path: ONLINE_MEDIA,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={ONLINE_MEDIA_CLIPPING} replace />
      },
      {
        path: 'statistic',
        element: <MonitoringOnlineMediaStatistic />
      },
      {
        path: 'clipping',
        element: <MonitoringOnlineMediaClipping />
      },
      {
        path: 'clipping/:id',
        element: <MonitoringOnlineMediaClippingById />
      },
      {
        path: 'comparison',
        element: <MonitoringOnlineMediaComparison />
      }
    ]
  },
  {
    path: OFFLINE_MEDIA,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={OFFLINE_MEDIA_CLIPPING} replace />
      },
      {
        path: 'statistic',
        element: <MonitoringOfflineMediaStatistic />
      },
      {
        path: 'clipping',
        element: <OfflineMediaClipping />
      },
      {
        path: 'clipping/:id',
        element: <MonitoringOfflineMediaClippingById />
      },
      {
        path: 'comparison',
        element: <OfflineMediaComparison />
      }
    ]
  },
  {
    path: CUSTOM_DASHBOARD,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <CustomDashboard />
      }
      // {
      //   path: CUSTOM_DASHBOARD,
      //   element: (
      //     <ProtectedRouter isAuth={isAuthenticated}>
      //       <CustomDashboard />
      //     </ProtectedRouter>
      //   )
      // }
    ]
  },
  {
    path: CUSTOM_REPORT,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: isCustomReport ? (
          <CustomReport />
        ) : (
          <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
        )
      },
      {
        path: '/:id',
        element: isCustomReport ? (
          <CustomReportById />
        ) : (
          <Navigate to={SOCIAL_MEDIA_CONTENT} replace />
        )
      }
    ]
  },
  {
    path: STREAM_PANEL,
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={STREAM_PANEL_SOCIAL_MEDIA} replace />
      },
      {
        path: 'social_media',
        element: <StreamPanelSocialMedia />
      },
      {
        path: 'online_media',
        element: <StreamPanelOnlineMedia />
      },
      {
        path: 'people_panel',
        element: <PeoplePanel />
      },
      {
        path: 'offline_media',
        element: <StreamPanelOfflineMedia />
      }
    ]
  },
  {
    path: ONBOARDING,
    element: (
      <ProtectedOnboarding isOnboarding={isOnboarding}>
        <BasicLayout />
      </ProtectedOnboarding>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={ONBOARDING_ADD_PROFILE} replace />
      },
      {
        path: 'add_profile',
        element: <CreateProfile />
      },
      {
        path: 'create_project',
        element: <CreateProject />
      },
      {
        path: 'social_profile',
        element: <CreateSocialProfile />
      },
      {
        path: 'setup_progress',
        element: <SetupProgress />
      },
      {
        path: 'keyword_or_hashtag',
        element: <CreateKeywordHashtag />
      },
      {
        path: 'keyword',
        element: <UsersSignIn />
      },
      {
        path: 'clipping',
        element: <CreateClipping />
      },
      { path: 'entity', element: <CreateEntity /> },
      {
        path: 'populating_data',
        element: <PopulatingData />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/crypto" replace />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/management/transactions" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: <Navigate to="details" replace />
          }
        ]
      }
    ]
  },
  {
    path: 'loading-process',
    element: <LoadingProcess />
  },
  {
    path: 'register-invitation',
    element: <RegisterInvitation />
  },
  {
    path: 'warning',
    element: <BaseLayout />,
    children: [
      {
        path: 'object_overlap',
        element: <ObjectOverlap />
      }
    ]
  },
  {
    path: 'billing',
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <BasicLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: 'stream_plan',
        element: <UpgradeStreamQuota />
      },
      {
        path: 'stream_checkout',
        element: <CheckoutStream />
      },
      {
        path: 'update_plan',
        element: <UpdatePlan />
      },
      {
        path: 'choose_your_plan',
        element: <ChooseYourPlan />
      },
      {
        path: 'plan_checkout',
        element: <CheckoutPlan />
      },
      {
        path: 'manage_objects',
        element: <ManageObjectOverlap />
      }
    ]
  },
  {
    path: 'register-invitation',
    element: <RegisterInvitation />
  },
  {
    path: 'settings',
    element: (
      <ProtectedRouter isAuth={isAuthenticated}>
        <SidebarLayout />
      </ProtectedRouter>
    ),
    children: [
      {
        path: '/',
        element: <Settings />
      },
      {
        path: 'objects',
        element: <SettingsObjects />
      },
      {
        path: 'group',
        element: <SettingsGroup />
      },
      {
        path: 'label',
        element: <SettingsLabel />
      },
      {
        path: 'entity',
        element: <SettingsEntity />
      },
      {
        path: 'custom_dashboard',
        element: <SettingsCustomDashboard />
      },
      {
        path: 'auto-label/form',
        element: <AddAutoLabel />
      },
      {
        path: 'object_overlap',
        element: <ObjectOverlap />
      },
      { path: 'auto-label-onm/form', element: <AddAutoLabelOnm /> },
      { path: 'stop-words', element: <SettingsStopWords /> }
    ]
  }
];

export default routes;
