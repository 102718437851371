import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Country,
  PaymentGroup,
  PlanItem,
  PlanSimulation,
  PlanSimulationResponse,
  UserPackagePlan
} from 'src/models/register';
import { ApolloQueryResult } from '@apollo/react-hooks';

interface Register {
  userPackagePlan: ApolloQueryResult<Array<UserPackagePlan>>;
  planSimulation: ApolloQueryResult<PlanSimulation>;
  countries: ApolloQueryResult<Array<Country>>;
  selectedCountryId: string;
  xenditPaymentChannel: ApolloQueryResult<Array<PaymentGroup>>;
  countryId: string;
  planSimulationV2: Pick<PlanSimulationResponse, 'promoCode' | 'planItems'>;
}

const initialState: Register = {
  userPackagePlan: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  planSimulation: {
    data: {
      isUsedPromo: false,
      isPromoValid: false,
      isLtd: false,
      promoErrorMessage: '',
      currency: 'idr',
      currencyBySelectedCountry: 'idr',
      quotaBasic: 0,
      quotaAdvance: 0,
      quotaOnlineMedia: 0,
      total: 0,
      totalBySelectedCountry: 0,
      period: 1,
      promoType: null,
      merchantName: 'xendit',
      promoCode: null,
      symbolBySelectedCountry: '',
      packagePlan: null
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  planSimulationV2: {
    promoCode: '',
    planItems: []
  },
  countries: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  selectedCountryId: '',
  xenditPaymentChannel: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  countryId: ''
};

export const register = createSlice({
  name: 'register',
  initialState,
  reducers: {
    reducerUserPackagePlan: (
      state: Register,
      action: PayloadAction<ApolloQueryResult<Array<UserPackagePlan>>>
    ) => {
      state.userPackagePlan = action.payload;
    },
    reducerUserPlanSimulation: (
      state: Register,
      action: PayloadAction<ApolloQueryResult<PlanSimulation>>
    ) => {
      state.planSimulation = action.payload;
    },
    reducerCountries: (
      state: Register,
      action: PayloadAction<ApolloQueryResult<Array<Country>>>
    ) => {
      state.countries = action.payload;
    },
    reducerChangeSelectedCountryId: (
      state: Register,
      action: PayloadAction<string>
    ) => {
      state.selectedCountryId = action.payload;
    },
    reducerXenditPaymentChannel: (
      state: Register,
      action: PayloadAction<ApolloQueryResult<Array<PaymentGroup>>>
    ) => {
      state.xenditPaymentChannel = action.payload;
    },
    reducerUpdateCountryId: (
      state: Register,
      action: PayloadAction<string>
    ) => {
      state.countryId = action.payload;
    },
    reducerUpdateSimulationPromoCode: (
      state: Register,
      action: PayloadAction<string>
    ) => {
      state.planSimulationV2 = {
        ...state.planSimulationV2,
        promoCode: action.payload
      };
    },
    reducerUpdateSimulationPlanItems: (
      state: Register,
      action: PayloadAction<PlanItem[]>
    ) => {
      state.planSimulationV2 = {
        ...state.planSimulationV2,
        planItems: action.payload
      };
    }
  }
});

export const {
  reducerUserPackagePlan,
  reducerUserPlanSimulation,
  reducerCountries,
  reducerChangeSelectedCountryId,
  reducerXenditPaymentChannel,
  reducerUpdateCountryId,
  reducerUpdateSimulationPromoCode,
  reducerUpdateSimulationPlanItems
} = register.actions;

export default register.reducer;
