import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGroupObject,
  IObject,
  IObjectInput,
  IObjectLimit,
  IObjectManagementStreamPreview,
  ISocialProfileAutoComplete,
  TObjectFilter
} from 'src/models/objectManagement';
import { IAutoClippingPayload } from 'src/models/onlineMedia';
import { IEntityOutput } from 'src/services/useOnboarding';

interface IObjectManagement {
  socialProfileAutoComplete: ApolloQueryResult<
    Array<ISocialProfileAutoComplete>
  >;
  tempInputObjects: Array<IObjectInput>;
  tempInputObjectsCompetitor: Array<IObjectInput>;
  objectList: ApolloQueryResult<Array<IObject>>;
  hashtagList: ApolloQueryResult<Array<IObject>>;
  groupObject: ApolloQueryResult<Array<IGroupObject>>;
  groupSelectedById: string;
  objectListByObjectType: Array<IObject>; // current active objects
  tempInputKeywordOrHashtag: Array<IObjectInput>;
  streamPreview: ApolloQueryResult<IObjectManagementStreamPreview | undefined>;
  objectLimit: ApolloQueryResult<IObjectLimit>;
  objectType: TObjectFilter; // for filter object list in setting page
  selectedObjectType: TObjectFilter; // for filter object list in analytics pages
  // for metrics onboarding
  tempInputClipping: Array<IAutoClippingPayload>;
  tempInputEntity: Array<IEntityOutput>;
}

const initialState: IObjectManagement = {
  socialProfileAutoComplete: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  tempInputObjects: [],
  tempInputObjectsCompetitor: [],
  objectList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  objectListByObjectType: [],
  hashtagList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  tempInputKeywordOrHashtag: [],
  streamPreview: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  groupObject: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  groupSelectedById: '_default',
  objectLimit: {
    data: {
      accountQuota: 0,
      accountUsed: 0,
      keywordQuota: 0,
      keywordUsed: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  objectType: 'all',
  selectedObjectType: 'all',
  tempInputClipping: [],
  tempInputEntity: []
};

export const objectManagement = createSlice({
  name: 'objectManagement',
  initialState,
  reducers: {
    reducerUpdateSocialProfileAutoCompleteLoading: (
      state: IObjectManagement,
      action: PayloadAction<boolean>
    ) => {
      state.socialProfileAutoComplete = {
        ...state.socialProfileAutoComplete,
        loading: action.payload
      };
    },
    reducerUpdateSocialProfileAutoComplete: (
      state: IObjectManagement,
      action: PayloadAction<
        ApolloQueryResult<Array<ISocialProfileAutoComplete>>
      >
    ) => {
      state.socialProfileAutoComplete = action.payload;
    },
    reducerClearSocialProfileAutoComplete: (state: IObjectManagement) => {
      state.socialProfileAutoComplete.data = [];
    },
    reducerUpdateTempObjectInput: (
      state: IObjectManagement,
      action: PayloadAction<IObjectInput>
    ) => {
      state.tempInputObjects = [...state.tempInputObjects, action.payload];
    },
    reducerRemoveTempObjectInput: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputObjects = state.tempInputObjects.filter(
        (object) => object.content !== action.payload
      );
    },
    reducerClearTempObjectInput: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputObjects = [];
    },
    reducerUpdateTempKeywordOrHashtag: (
      state: IObjectManagement,
      action: PayloadAction<IObjectInput>
    ) => {
      state.tempInputKeywordOrHashtag = [
        ...state.tempInputKeywordOrHashtag,
        action.payload
      ];
    },
    reducerRemoveTempKeywordOrHashtag: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputKeywordOrHashtag = state.tempInputKeywordOrHashtag.filter(
        (object) => object.content !== action.payload
      );
    },
    reducerUpdateObjectListByObjectType: (
      state: IObjectManagement,
      action: PayloadAction<Array<IObject>>
    ) => {
      state.objectListByObjectType = action.payload;
    },
    reducerUpdateTempObjectInputCompetitor: (
      state: IObjectManagement,
      action: PayloadAction<IObjectInput>
    ) => {
      state.tempInputObjectsCompetitor = [
        ...state.tempInputObjectsCompetitor,
        action.payload
      ];
    },
    reducerRemoveTempObjectInputCompetitor: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputObjectsCompetitor =
        state.tempInputObjectsCompetitor.filter(
          (object) => object.content !== action.payload
        );
    },
    reducerUpdateObjectList: (
      state: IObjectManagement,
      action: PayloadAction<ApolloQueryResult<Array<IObject>>>
    ) => {
      state.objectList = action.payload;
    },
    reducerUpdateHashtagList: (
      state: IObjectManagement,
      action: PayloadAction<ApolloQueryResult<Array<IObject>>>
    ) => {
      state.hashtagList = action.payload;
    },
    reducerUpdateGroupObject: (
      state: IObjectManagement,
      action: PayloadAction<ApolloQueryResult<Array<IGroupObject>>>
    ) => {
      state.groupObject = action.payload;
    },
    reducerUpdateObjectManagementStreamPreview: (
      state: IObjectManagement,
      action: PayloadAction<ApolloQueryResult<IObjectManagementStreamPreview>>
    ) => {
      state.streamPreview = action.payload;
    },
    reducerUpdateObjectManagementStreamPreviewLoading: (
      state: IObjectManagement,
      action: PayloadAction<boolean>
    ) => {
      state.streamPreview.loading = action.payload;
    },
    reducerClearObjectManagementStreamPreview: (
      state: IObjectManagement,
      action: PayloadAction<undefined>
    ) => {
      state.streamPreview.data = action.payload;
    },
    reducerUpdateObjectManagementGroupSelectedById: (
      state: IObjectManagement,
      action: PayloadAction<string | undefined>
    ) => {
      state.groupSelectedById = action.payload;
    },
    reducerUpdateObjectManagementLimit: (
      state: IObjectManagement,
      action: PayloadAction<ApolloQueryResult<IObjectLimit>>
    ) => {
      state.objectLimit = action.payload;
    },
    reducerUpdateObjectType: (
      state: IObjectManagement,
      action: PayloadAction<TObjectFilter>
    ) => {
      state.objectType = action.payload;
    },
    reducerUpdateSelectedObjectType: (
      state: IObjectManagement,
      action: PayloadAction<TObjectFilter>
    ) => {
      state.selectedObjectType = action.payload;
    },
    reducerUpdateTempInputClipping: (
      state: IObjectManagement,
      action: PayloadAction<IAutoClippingPayload>
    ) => {
      state.tempInputClipping = [...state.tempInputClipping, action.payload];
    },
    reducerRemoveTempInputClipping: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputClipping = state.tempInputClipping.filter(
        (clipping) => clipping.id !== action.payload
      );
    },
    reducerUpdateTempEntityInput: (
      state: IObjectManagement,
      action: PayloadAction<IEntityOutput>
    ) => {
      state.tempInputEntity = [...state.tempInputEntity, action.payload];
    },
    reducerRemoveTempEntityInput: (
      state: IObjectManagement,
      action: PayloadAction<string>
    ) => {
      state.tempInputEntity = state.tempInputEntity.filter(
        (object) => object.entityId !== action.payload
      );
    },
    reducerClearTempEntityInput: (
      state: IObjectManagement
      // action: PayloadAction<string>
    ) => {
      state.tempInputEntity = [];
    }
  }
});

export const {
  reducerUpdateSocialProfileAutoComplete,
  reducerUpdateSocialProfileAutoCompleteLoading,
  reducerClearSocialProfileAutoComplete,
  reducerUpdateTempObjectInput,
  reducerRemoveTempObjectInput,
  reducerClearTempObjectInput,
  reducerUpdateTempObjectInputCompetitor,
  reducerRemoveTempObjectInputCompetitor,
  reducerUpdateObjectList,
  reducerUpdateGroupObject,
  reducerUpdateTempKeywordOrHashtag,
  reducerRemoveTempKeywordOrHashtag,
  reducerUpdateObjectListByObjectType,
  reducerUpdateObjectManagementStreamPreview,
  reducerUpdateObjectManagementStreamPreviewLoading,
  reducerClearObjectManagementStreamPreview,
  reducerUpdateObjectManagementGroupSelectedById,
  reducerUpdateObjectManagementLimit,
  reducerUpdateHashtagList,
  reducerUpdateObjectType,
  reducerUpdateSelectedObjectType,
  reducerUpdateTempInputClipping,
  reducerRemoveTempInputClipping,
  reducerUpdateTempEntityInput,
  reducerRemoveTempEntityInput,
  reducerClearTempEntityInput
} = objectManagement.actions;

export default objectManagement.reducer;
