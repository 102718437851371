import gql from 'graphql-tag';

export const GET_USER_PROFILE = gql`
  query user_me {
    user_me {
      companyId
      userId
      email
      username
      company
      emailNeedVerification
      projectList {
        id
        name
        displayName
        coverImageReport
        colorTitleReport
        titleReport
        objectLimitation
        logo
        objectNeedSet
        status
        message
        planType
        role
        isLtd
        isProviderLtd
        isObjectOverlap
        onBoarding {
          advanceObjectQuota
          advanceObjectQuotaUsed
          basicObjectQuota
          basicObjectQuotaUsed
          industryId
          isOnBoarding
          onlineMediaObjectQuota
          onlineMediaObjectQuotaUsed
          entityMediaObjectQuota
          entityMediaObjectQuotaUsed
          role
        }
        flags {
          isOnlineMedia
          isOfflineMedia
          isSocialMedia
          isCustomDownloadCsv
          isIntegratedWithSlack
          isIntegratedWithTrello
          isIntegratedWithGoogleSheet
          isComparison
          isDownload
          isSnaDna
          isPeoplePanel
          isCustomReport
          isClustering
        }
        customerType
        subscriptionEndDate
        __typename
      }
      __typename
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  query user_sendEmailVerification($userId: Id!) {
    user_sendEmailVerification(userId: $userId) {
      isSend
    }
  }
`;

export const GET_ROLE = gql`
  query user_getRole {
    user_getRole {
      label
      value
    }
  }
`;

export const LIST_MEMBER = gql`
  query user_listMember($projectId: Id!, $limit: Int!, $page: Int!) {
    user_listMember(projectId: $projectId, limit: $limit, page: $page) {
      result {
        email
        fullName
        id
        role
        userId
      }
      totalRows
      totalPages
      page
      limit
      __typename
    }
  }
`;

export const LIST_PENDING_INVITATION = gql`
  query user_listPendingInvitation($projectId: Id!, $limit: Int!, $page: Int!) {
    user_listPendingInvitation(
      projectId: $projectId
      limit: $limit
      page: $page
    ) {
      result {
        id
        email
        role
        createdAt
        expiredAt
      }
      totalRows
      totalPages
      page
      limit
      __typename
    }
  }
`;
