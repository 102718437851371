import { useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import useQueryUrl from 'src/hooks/useQuery';
import { useAppSelector } from 'src/app/hooks';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderSettings() {
  const { pathname } = useLocation();
  const query = useQueryUrl();
  const navigate = useNavigate();
  const tabQuery = query.get('tab');
  const [tab, setTab] = useState<string>(tabQuery);
  const isKompasWhiteLabel = window.location.hostname.includes('kompas');
  const userType = useAppSelector((state) => state.users.project.customerType);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Typography variant="h1">Settings</Typography>
        </Grid>
        <Grid item lg={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 3 }}>
            <Tabs
              value={!tab ? 'monitored_assets' : tab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Monitored Assets" value="monitored_assets" />
              <Tab label="Membership" value="membership" />
              <Tab label="Team" value="team" />
              <Tab label="Alert System" value="alert_system" />
              {!isKompasWhiteLabel ? (
                <Tab label="Integration" value="integration" />
              ) : null}
              {userType === 'b2c' ? (
                <Tab label="Billing System" value="billing" />
              ) : null}
            </Tabs>
          </Box>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
}

export default HeaderSettings;
