import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerSignIn,
  reducerUserProfile,
  reducerListMember,
  reducerPendingInvitation,
  reducerListRole,
  reducerSetProject
} from 'src/features/users';
import { useAlert } from 'src/hooks/useAlert';
import {
  IAuthenticatedUserWithPublicCredential,
  IMember,
  IMemberWithPagination,
  IProject,
  IRole
} from 'src/models/users';
import { USERS_SIGN_IN } from 'src/route';
import {
  POST_USER_SIGN_IN,
  INVITE_USER,
  EDIT_MEMBER,
  DELETE_MEMBER,
  CANCEL_INVITATION,
  CHANGE_PASSWORD,
  USER_CREATE_TOKEN,
  IDENTIFY_EMAIL,
  RESET_PASSWORD
} from './graphql/users/mutation';
import {
  GET_USER_PROFILE,
  LIST_MEMBER,
  LIST_PENDING_INVITATION,
  GET_ROLE
} from './graphql/users/query';
import { usePostHog } from 'posthog-js/react';
import { IPostHogUserProps } from 'src/models/general';
import moment from 'moment';
import { reducerUpdateAnalyticArgumentDateRange } from 'src/features/analyticArguments';

export interface IPaginationPayload {
  projectId: string;
  limit: number;
  page: number;
}

interface IGetUserResponse {
  user_me: IAuthenticatedUserWithPublicCredential;
}

interface IGetListMemberResponse {
  user_listMember: Array<IMember>;
}

interface IGetPendingInvitationResponse {
  user_listPendingInvitation: IMemberWithPagination;
}

interface IGetRolesResponse {
  user_getRole: Array<IRole>;
}

const useUsers = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const posthog = usePostHog();
  const { handleClickAlert } = useAlert();
  const dateRange = useAppSelector(
    (state) => state.analyticArguments.dateRange
  );

  const fetchUserProfile = async function () {
    return await client
      .query<IGetUserResponse>({
        query: GET_USER_PROFILE
      })
      .then((response) => {
        if (response.error) {
          handleClickAlert({
            horizontal: 'center',
            vertical: 'top',
            message: 'Error fetching user profile',
            severity: 'error'
          });
          return;
        }
        return { ...response, data: response.data.user_me };
      });
  };

  const updateProjectActive = (projectList: Array<IProject>) => {
    let project: IProject = undefined;
    const projectAvailable = window.localStorage.getItem('project');
    if (projectAvailable) {
      project = projectList.find((project) => project.id === projectAvailable);
    } else {
      project = projectList[0];
    }

    // limit initial date range to subscription period
    const terminatedDate = moment(project.subscriptionEndDate)
      .add(14, 'days')
      .toDate();
    let payloadDateRange = { ...dateRange };
    const isEndAfterTermination = moment(payloadDateRange.end).isAfter(
      terminatedDate
    );

    // Adjust end date to termination date
    if (isEndAfterTermination) {
      payloadDateRange = {
        ...payloadDateRange,
        end: moment(terminatedDate).toDate()
      };
    }
    dispatch(reducerUpdateAnalyticArgumentDateRange(payloadDateRange));
    dispatch(reducerSetProject(project));
    window.localStorage.setItem('project', project.id);
  };

  const getUserProfile = async function () {
    try {
      const response = await fetchUserProfile();
      if (!response.data) return;
      dispatch(reducerUserProfile(response));
      const { projectList } = response.data;
      if (projectList.length < 1) {
        window.localStorage.removeItem('project');
        window.localStorage.removeItem('token');
        window.location.assign(USERS_SIGN_IN);
      }
      updateProjectActive(projectList);
    } catch (e) {
      window.localStorage.removeItem('project');
      window.localStorage.removeItem('token');
      window.location.assign(USERS_SIGN_IN);
    }
  };

  const [postUserSignin, responseUserSignIn] = useMutation(POST_USER_SIGN_IN, {
    onCompleted: ({ session, user_logInForOpenBeta }) => {
      const isAuth = session.token ? true : false;
      window.localStorage.setItem('token', session.token);

      // send logged in user properties to posthog
      const currentProject = user_logInForOpenBeta.projectList[0];
      const userProps: IPostHogUserProps = {
        email: user_logInForOpenBeta.email,
        userId: user_logInForOpenBeta.userId,
        projectId: currentProject.id,
        projectName: currentProject.displayName
      };
      // posthog.identify(user_logInForOpenBeta.email, userProps);

      dispatch(reducerSignIn(isAuth));
    },
    onError: (error) => {
      if (!error) return;
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Email or company not found',
        severity: 'error'
      });
    }
  });

  const fetchListMember = async function (payload: IPaginationPayload) {
    return await client
      .query<IGetListMemberResponse>({
        query: LIST_MEMBER,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.user_listMember };
      });
  };

  const getListMember = async function (payload: IPaginationPayload) {
    const response = await fetchListMember(payload);
    if (!response.data) return;
    dispatch(reducerListMember(response));
  };

  const fetchPendingInvitation = async function (payload: IPaginationPayload) {
    return await client
      .query<IGetPendingInvitationResponse>({
        query: LIST_PENDING_INVITATION,
        variables: {
          projectId: payload.projectId,
          limit: 100,
          page: 1
        }
      })
      .then((response) => {
        return { ...response, data: response.data.user_listPendingInvitation };
      });
  };

  const getPendingInvitation = async function (payload: IPaginationPayload) {
    const response = await fetchPendingInvitation(payload);
    if (!response.data) return;
    dispatch(reducerPendingInvitation(response));
  };

  const fetchListRole = async function () {
    return await client
      .query<IGetRolesResponse>({
        query: GET_ROLE
      })
      .then((response) => {
        return { ...response, data: response.data.user_getRole };
      });
  };

  const getListRole = async function () {
    const response = await fetchListRole();
    if (!response.data) return;
    dispatch(reducerListRole(response));
  };

  const [addMember, responseAddMember] = useMutation(INVITE_USER, {
    onCompleted: (response) => {
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'The invitation has been sent',
        severity: 'success'
      });
    }
  });
  const [editMember, responseEditMember] = useMutation(EDIT_MEMBER);
  const [deleteMember, responseDeleteMember] = useMutation(DELETE_MEMBER);
  const [cancelInvitation, responseCancelInvitation] = useMutation(
    CANCEL_INVITATION,
    {
      onCompleted: (response) => {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: 'Invitation successfully canceled',
          severity: 'success'
        });
      }
    }
  );

  const [changePassword, responseChangePassword] = useMutation(
    CHANGE_PASSWORD,
    {
      onError: (error) => {
        if (!error) return;
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: 'wrong password',
          severity: 'error'
        });
      }
    }
  );

  const [createToken, resCreateToken] = useMutation(USER_CREATE_TOKEN, {
    onError: (error) => {
      if (!error) return;
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Token failed created',
        severity: 'error'
      });
    }
  });

  const [identifyEmail, resIdentifyEmail] = useMutation(IDENTIFY_EMAIL, {
    onCompleted: () => {
      navigate(USERS_SIGN_IN);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Email sent'
      });
    },
    onError(err) {
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Email not found!',
        severity: 'error'
      });
      console.log(err);
    }
  });

  const [resetPassword, resResetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      navigate(USERS_SIGN_IN, { replace: true });
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Password changed'
      });
    },
    onError(err) {
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: 'Error resetting password, please try again'
      });
      console.log(err);
    }
  });

  return {
    postUserSignin,
    responseUserSignIn,
    getUserProfile,
    getListMember,
    getPendingInvitation,
    addMember,
    responseAddMember,
    editMember,
    responseEditMember,
    deleteMember,
    cancelInvitation,
    getListRole,
    responseDeleteMember,
    responseCancelInvitation,
    changePassword,
    responseChangePassword,
    createToken,
    resCreateToken,
    identifyEmail,
    resIdentifyEmail,
    resetPassword,
    resResetPassword
  };
};

export default useUsers;
